<template>
  <div>
    <el-dialog :close-on-click-modal="false" title="创建客户" :visible.sync="smVisible" width="630px"
               :before-close="closeEvent">
      <div class="save-member">
        <el-form ref="mb" label-width="88px" :model="form" :rules="rules" size="mini">
          <span class="qian-bt">基本信息</span>
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="姓名:" prop="memberName">
                  <el-input v-model="form.memberName" placeholder="请输入姓名" style="width: 180px"></el-input>
                </el-form-item>
              </div>
              <div class="grid-content bg-purple">
                <el-form-item label="手机号:" prop="phone">
                  <el-input v-model="form.phone" maxlength="11" show-word-limit style="width: 180px"
                            placeholder="请输入手机号"></el-input>
                </el-form-item>
              </div>
              <div class="grid-content bg-purple-light">
                <el-form-item label="性别:" prop="sex">
                  <el-radio-group v-model="form.sex" style="width: 180px">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-form-item label="生日:" prop="birthdayDate">
                  <el-date-picker
                    @change="setDate(form.birthdayDate)"
                    v-model="form.birthdayDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    style="width: 180px"
                  ></el-date-picker>
                  <el-input v-model="form.ylDate" v-if="form.birthdayDate" disabled  style="width: 180px;margin-top: 10px"></el-input>
                  <el-radio-group v-model="form.srRadio" style="margin-top: 5px">
                    <el-radio :label="1">阳历</el-radio>
                    <el-radio :label="2">阴历</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <span class="qian-bt">高级设置</span>
          <el-form-item label="客户来源:" prop="mbSource" style="margin-left: 10px">
            <el-radio-group v-model="form.mbSource" style="width: 500px">
              <el-radio style="width: 68px;margin-bottom: 6px" :label="0">自来</el-radio>
              <el-radio style="width: 68px" :label="1">抖音</el-radio>
              <el-radio style="width: 68px" :label="2">美团</el-radio>
              <el-radio style="width: 68px;margin-bottom: 6px" :label="3">小红书</el-radio>
              <el-radio style="width: 68px;" :label="5">私域商城</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row style="margin-left: 10px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-form-item label="推荐人:" prop="superiorId">
                  <el-input v-model="tjmbName" placeholder="姓名/手机号" style="width: 180px">
                    <el-button slot="append" icon="el-icon-search" @click="toTjmbList"></el-button>
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-form-item label="推荐人:" prop="superiorId" v-if="tjmb.id">
                  <el-input v-model="tjmb.memberName" disabled style="width: 165px">
                  </el-input>
                  <span style="color: #ff4d51;cursor: pointer;margin-left: 5px"@click="delYq">删除</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-form-item label="注册时间:" prop="createDate" style="margin-left: 10px">
            <el-date-picker
              v-model="form.createDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              style="width: 180px"
            ></el-date-picker>
          </el-form-item>
          <span class="qian-bt">客户分配</span>
          <el-form-item label="第一负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.oneId" placeholder="请选择">
              <el-option
                v-for="item in fEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.twoId" placeholder="请选择">
              <el-option
                v-for="item in sEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三负责人:" prop="superiorId" style="margin-left: 10px">
            <el-select v-model="form.threeId" placeholder="请选择">
              <el-option
                v-for="item in tEmps"
                :key="item.id"
                :label="item.nickName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:" prop="remarks" style="margin-left: 10px">
            <el-input
              style="width: 280px"
              type="textarea"
              :rows="6"
              v-model="form.remarks"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="saveMember">确 定</el-button>
          <el-button size="small" type="" @click="closeEvent">取 消</el-button>
        </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="邀请人" :visible.sync="yqVisible" width="300px"
               :before-close="yqEvent">
      <div class="yqlist">
        <el-table
          :data="tjmbs"
          @row-click="setTjmb"
          size="mini"
          height="300"
          style="width: 100%">
          <el-table-column prop="memberName" label="姓名" align="center"/>
          <el-table-column prop="phone" label="手机号" align="center"/>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {getToken} from "../../../utils/auth";
  import dateFormat from '../../../utils/date'
  export default {
    data() {
      return {
        form: {
          memberName: "",
          phone: "",
          sex: 1,
          mbSource: 0,
          remarks: "",
          birthdayDate:'',
          createDate: "",
          ylDate: '',
          srRadio: 1,
          oneId: '',
          twoId: '',
          threeId: '',
          token: getToken(),
        },

        yqVisible: false,
        tjmbName: '',
        tjmbs: [],
        tjmb: {},

        fEmps: [],
        sEmps: [],
        tEmps: [],

        rules: {
          memberName: [
            {required: true, message: '请输入名称', trigger: 'blur'}
          ],
          phone: [
            {required: true, message: '请输入手机号', trigger: 'blur'}
          ],
          sex: [
            {required: true, message: '请选择性别', trigger: 'blur'}
          ],
          createDate: [
            {required: true, message: '注册时间', trigger: 'blur'}
          ],
          mbSource: [
            {required: true, message: '请选择来源', trigger: 'blur'}
          ]
        },
      }
    },
    props: {
      smVisible: Boolean
    },
    created() {
      this.getEmpList();
    },
    methods: {
      setDate(date){
        this.form.ylDate = this.formatToLunar(new Date(date))
      },
      formatToLunar(date) {
        if (!date) return '';
        var time =dateFormat.solar2lunar(date.getFullYear(),date.getMonth() + 1,date.getDate())
        return time.IMonthCn+time.IDayCn;
      },
      async toTjmbList() {
        if (!this.tjmbName) {
          this.$message.error("请输入查询条件");
          return;
        }
        let data = {
          tjmbName: this.tjmbName,
          token: getToken(),
        }
        let res = await this.$get("/sw/getSwTjmbs", data);
        this.tjmbs = res.list
        this.yqVisible = true
      },
      setTjmb(item) {
        this.tjmb = item
        this.yqEvent()
      },
      yqEvent() {
        this.yqVisible = false
      },
      delYq(){
        this.tjmbs=[],
        this.tjmbName=''
        this.tjmb=''
      },
      closeEvent() {
        this.$emit('closeSM')
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
      },

      saveMember() {
        this.$refs.mb.validate(async valid => {
          if (valid) {
            if (this.tjmb && this.tjmb.id) {
              this.form.superiorId = this.tjmb.id
            }
            let res = await this.$post("/sw/saveMember", this.form);
            if (res.code == 200) {
              this.$emit('setMember', res.member)
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          } else {
            return false
          }
        })
      },
    }
  }
</script>
<style>
  .el-dialog__body {
    border-top: silver solid 1px;
  }

  .save-member {
  }

  .qian-bt {
    border-left: #1256FA solid 4px;
    padding-left: 3px;
    font-size: 15px;
    display: block;
    margin-bottom: 16px;
  }

  .yqlist {

  }
</style>
